window._ = require('../libs/lodash.js')
window.Sortable = require('../libs/Sortable.min.js')

require('../libs/select2/select2.full.min.js')
require('../libs/select2/select2.min.css')

window.Handlebars = require('../libs/handlebars.js')
require('../js/message.js')
require('../js/constants.js')
require('../js/ui.js')
require('../js/util.js')
window.Path = require('../libs/path.min.js')

