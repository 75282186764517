esapp.consts = {}
esapp.consts.tips = {
    'LOGIN_FIRST': '请先登录',
    'LOGIN_FIRST_EN': 'Require login',
    'LOGIN_FIRST_MSG_BZ': 'user.login.required',
    'INVALID_ORDER': '订单无效',
    'INVALID_ORDER_EN': 'Invalid order'
}

// esapp.consts.sendOrderStatus = {
//     SENDORDER_AWAIT: "未发送",
//     SENDORDER_PUBLISH: "已发送"
// }
esapp.consts.container_status = {
    NO_ACCEPTED: "CONTAINER_NO_ACCEPTED", //待受理
    ACCEPTED: "CONTAINER_ACCEPTED", //已受理
    DISPATCHED: "CONTAINER_DISPATCHED", //已派車
    PROCESSING: "CONTAINER_PROCESSING",  //運輸中
    COMPLETE: "CONTAINER_COMPLETE",  //已完成
    CANCELLED: "CONTAINER_CANCELLED",  //  已取消
    TERMINATION: "CONTAINER_TERMINATION" //已退关
}
// esapp.consts.container_audit_status = {
//     CONTAINER_AUDIT_STATUS_AWAIT_SUPERIOR: "待上级审核", //待上级审核
//     CONTAINER_AUDIT_STATUS_AUDITED: "已审核", //已审核
//     CONTAINER_AUDIT_STATUS_AWAIT: "未审核" //未审核
// }

esapp.consts.ctn_status = {
    NO_ACCEPTED: "CONTAINER_NO_ACCEPTED", //待受理
    ACCEPTED: "CONTAINER_ACCEPTED", //已受理
    WAIT_DISPATCH: 'CONTAINER_WAIT_DISPATCH', //待调度
    WAIT_GRABORDER: 'CONTAINER_WAIT_GRABORDER',//待抢单
    CONFIRM_ORDER: 'CONTAINER_CONFIRM_ORDER',//待确认抢单
    DISPATCHED: "CONTAINER_DISPATCHED", //已派車
    WAIT_AUDIT: 'CONTAINER_WAIT_AUDIT', //待审核定价
}

// esapp.consts.boxTime = [{
//     id: '',
//     text: '全部'
// }, {
//     id: 'dayBeforeYesterDay',
//     text: '前天'
// }, {
//     id: 'yesterday',
//     text: '昨天'
// }, {
//     id: 'today',
//     text: '今天'
// }, {
//     id: 'tomorrow',
//     text: '明天'
// }, {
//     id: 'dayAfterTomorrow',
//     text: '后天'
// }
// ]
// esapp.consts.orderStatus = [{
//     id: 'ORDER_UNHANDLED',
//     text: '代受理'
// }, {
//     id: 'ORDER_ALREADYFEEDBACK',
//     text: '已受理'
// }, {
//     id: 'ORDER_DISPATCHED',
//     text: '已派车'
// }, {
//     id: 'ORDER_TERMINATION',
//     text: '退关'
// }]
// esapp.consts.containerSizeType = [{
//     id: 'large',
//     text: '大箱'
// }, {
//     id: 'small',
//     text: '小箱'
// }]

esapp.consts.taskType = [{
    id: 'TASK_SINGLE',
    text: '单放'
}, {
    id: 'TASK_LCL',
    text: '拼出'
}, {
    id: 'TASK_LARGE',
    text: '大箱'
}, {
    id: 'TASK_TWIN',
    text: '双拖'
}, {
    id: 'TASK_MULTIPLE',
    text: '套班'
},{
    id:'TASK_GATE_IN',
    text:'二次进港'
}]

// esapp.consts.dispatchType = [{
//     id: 'ACTION_FUTURE_DRIVER',
//     text: '发未来车老板'
// }, {
//     id: 'ACTION_SEND',
//     text: '发一拼通'
// }, {
//     id: 'ACTION_SELF_SUPPORT',
//     text: '发自营车辆'
// }]
esapp.consts.dispatchType = [{
    id: '发未来车老板',
    text: '发未来车老板'
}, {
    id: '外发',
    text: '外发'
}, {
    id: '直接派单',
    text: '直接派单'
}, {
    id: '预约派单',
    text: '预约派单'
},{
    id: '集中调度',
    text: '集中调度'
},{
    id: '智能调度',
    text: '智能调度'
}, {
    id: '外发车队',
    text: '外发车队'
}, {
    id: '加盟车自动派单',
    text: '加盟车自动派单'
}, {
    id: '集中调度自动派单',
    text: '集中调度自动派单'
}, {
    id: 'EMPTY',
    text: '(空)'
}]

esapp.consts.retrieve_list_msg = ['操作非法，请先取消派车', '操作非法，请刷新重试', '存在已经建立双拖或套班的箱子']
