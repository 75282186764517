/*
 * @Author: wf
 * @Date:   2016-08-10 10:23:49
 * @Last Modified by:   wf
 * @Last Modified time: 2016-08-10 14:28:34
 */

'use strict';

if (!window.esapp) {
    window.esapp = {}
}
esapp.msg = {}

esapp.msg.get = function(key, arg) {
    if (this[key]) {
        return this[key]
    } else {
        console.warn('key not found: ' + key)
        return key;
    }
}
esapp.msg.terminationNeedReturnBack = "是否需要还箱"
esapp.msg.ismyReg = '不能含特殊字符'
esapp.msg.is_empty = '不能为空'
esapp.msg.isEmpty = '不能为空'
esapp.msg.is_email_prompt = '输入的邮箱格式不正确'
esapp.msg.is_number_prompt = '请输入正确的数字'
esapp.msg.is_mobile_prompt = '输入的手机号码格式不正确'
esapp.msg.isMobile = '手机格式不正确'
esapp.msg.is_IDCard_prompt = '输入的身份证号格式不正确'
esapp.msg.earlierGateInPayment = '预进港支付状态'
esapp.msg.defaultTitle = '鸭嘴兽'
esapp.msg.network_error_key = '因网络故障服务器响应超时，请重试'
esapp.msg.ajax_failed = "服务器开小差了，稍后再试吧。"
esapp.msg.close = "关闭"
esapp.msg.cancel = "取消"
esapp.msg.confirm = "确认"

esapp.msg.pricing = '发单价格'
esapp.msg.predictWeight = '下单货重'
esapp.msg.dispatchReason = '直接派单原因'
esapp.msg.orderAccept = '是否接单'
esapp.msg.driverName = "司机姓名"
esapp.msg.orderTaker = "接单人"
esapp.msg.takerPhone = "接单人手机"
esapp.msg.driverPhone = "司机电话"
esapp.msg.truckPlateNumber = "车牌"
esapp.msg.cancelReason = "取消原因"
esapp.msg.taskType = "类型"
esapp.msg.depot = "堆场名"
esapp.msg.belongedDriverName = "指定司机"
esapp.msg.planArrivalTime = '做箱时间'
esapp.msg.mailAddress = '寄单点'
esapp.msg.importantContainerReason = '重点跟箱原因'
esapp.msg.bidPrice = '中标金额'
esapp.msg.bidInfo = '司机出价'
esapp.msg.changeTruckPlateInfo='修改车牌号'
esapp.msg.changeDriverPhoneInfo='修改手机号'
esapp.msg.declarationOpportunityType  = '报关时间'
esapp.msg.gateinType  = '进港数据要求'
esapp.msg.defaultTitle = "鸭嘴兽"
esapp.msg.mailingInfoMsg = "寄单点"
esapp.msg.isTakeList = "是否拿单"
esapp.msg.truckInfo = "司机信息"
esapp.msg.taskIds = "任务ID"
esapp.msg.portDistrictChange = "港区"
esapp.msg.reason = "原因"
esapp.msg.orderNos = "订单号"
esapp.msg.customerService = "客服"
esapp.msg.terminateReason = "退关理由"

esapp.msg.blNo="提单号"
esapp.msg.isSoc="SOC箱"
esapp.msg.boundTypeId="进出口"
esapp.msg.acceptOrderRemark="接单备注"
esapp.msg.isUrgent="是否紧急录单"
esapp.msg.boundType="进/出口/顺风车"
esapp.msg.loadTypeId="装箱类型"
esapp.msg.fromPortCode="装货港编码"
esapp.msg.fromPort="装货港"
esapp.msg.toPortCode="卸货港"
esapp.msg.toPort="卸货港"
esapp.msg.eta="预计到港时间"
esapp.msg.etd="预计离港时间"
esapp.msg.openingTime="开港时间"
esapp.msg.closingTime="截港时间"
esapp.msg.vessel="船名"
esapp.msg.voyage="航次"
esapp.msg.placeOfDelivery="交货地"
esapp.msg.placeOfReceipt="接货地"
esapp.msg.shippingCompany="船公司"
esapp.msg.carrierId="船公司ID"
esapp.msg.isDangerousCargo="危险品"
esapp.msg.ifFreeze="冷藏品"
esapp.msg.isQuarantined="动卫检"
esapp.msg.temperature="冷藏箱温度"
esapp.msg.dgClass="危险品等级"
esapp.msg.unNo="联合国编号"
esapp.msg.quarantineNo="动检编号"
esapp.msg.quarantineAddress="动检地址"
esapp.msg.orderNo="订单号"
esapp.msg.businessNo="业务编号"
esapp.msg.buyer="客户"
esapp.msg.buyerContact="客户联系人"
esapp.msg.buyerPhone="客户联系电话"
esapp.msg.commissionTime="委托时间"
esapp.msg.status="订单状态"
esapp.msg.source="订单来源"
esapp.msg.portCityId="港口"
esapp.msg.vesselCode="后端用"
esapp.msg.terminalCode="还箱港区"
esapp.msg.isOrderHarbour="后端用"
esapp.msg.isTankWasher="修洗箱"
esapp.msg.tankWasherAddress="修洗箱地址"
esapp.msg.isQuarantine="动检"
esapp.msg.commissionType="委托类型"
esapp.msg.importantContainer="重点跟单"
esapp.msg.requiredReleaseDateTime="要求提供箱封号时间，"
esapp.msg.releaseContainerRequirement="放箱要求"
esapp.msg.pickupRequirement="提箱要求"
esapp.msg.dispatchRequirement="做箱要求"
esapp.msg.specialRequirement="特殊要求"
esapp.msg.estimatedWeight="下单货重"
esapp.msg.documentaryOffTime="截单时间"
esapp.msg.orderDocumentUrl="托书文件URL"
esapp.msg.documentCode="制单码"
esapp.msg.receiptNo="eir编号"
esapp.msg.requiredGateInTime="客户要求最晚进港时间"
esapp.msg.needDoubleConfirm="是否等通知做箱"
esapp.msg.blNoPrimary="主提单号"
esapp.msg.isCustomerNotifyApplyEir="是否客户通知放单"
esapp.msg.modifyCntrOperCde="修改箱营运人"
esapp.msg.LongreleaseOrderId="未来放箱订单id"
esapp.msg.needDeclared="是否需要鸭嘴兽报关"
esapp.msg.ctnSizeTypeCode="箱型"
esapp.msg.ctnSizeType="箱型"
esapp.msg.containerNo="箱号"
esapp.msg.sealNo="封号"
esapp.msg.internalBusinessNo="内部编号"
esapp.msg.sequenceNo="订单号序号"
esapp.msg.remark="箱子备注"
esapp.msg.cancelledBy="取消箱子的用户id"
esapp.msg.avgWeight="历史平均货重"
esapp.msg.terminal="还箱港区"
esapp.msg.depotCode="提箱堆场编码"
esapp.msg.factoryName="工厂"
esapp.msg.contactPerson="联系人"
esapp.msg.contactPhone="联系电话"
esapp.msg.doorArea="装/拆箱点4级地理位置名称"
esapp.msg.doorAddress="装/拆箱点详细地址"
esapp.msg.doorTime="做箱时间"
esapp.msg.chargeRemark="应收"
esapp.msg.costRemark="应付"
esapp.msg.productName="品名"
esapp.msg.weight="货重"
esapp.msg.packageQty="件数"
esapp.msg.volume="体积"
esapp.msg.tareWeight="皮重"
esapp.msg.cargoRemark="唛头"
esapp.msg.ctnOperatorCode="营运人预录要求"
esapp.msg.businessContainerId="客户内部的箱ID"
esapp.msg.VGMCutoff="出数据截单"


esapp.msg.portId="港口"
// esapp.msg.buyerId="客户名称"
// esapp.msg.success="success"
// esapp.msg.containers="containers"
// esapp.msg.inspection="是否检验监装"
// esapp.msg.haveDetails="haveDetails"
// esapp.msg.containersIds="containersIds"
esapp.msg.documentRemind="补录提示"
esapp.msg.depotName="堆场"
esapp.msg.transitPointName="拖车还箱码头"
// esapp.msg.portDistrictId="portDistrictId"
// esapp.msg.orderDocumentId="orderDocumentId"
// esapp.msg.containersDelete="containersDelete"
// esapp.msg.containersUpdate="containersUpdate"
// esapp.msg.hasMultipleDepot="hasMultipleDepot"
// esapp.msg.containerWeighing="过磅要求"
// esapp.msg.hasMultipleDoorTime="hasMultipleDoorTime"
// esapp.msg.remindWaitingCharges="remindWaitingCharges"
esapp.msg.transportBusinessType="运输业务类型"
esapp.msg.customerId="客户"

esapp.msg.CONTAINER_NO_ACCEPTED = '待确认做箱'
esapp.msg.CONTAINER_ACCEPTED = "待定价"
esapp.msg.CONTAINER_WAIT_AUDIT = "待审核定价"
esapp.msg.CONTAINER_WAIT_DISPATCH = "待调度"
esapp.msg.CONTAINER_WAIT_GRABORDER = "待抢单"
esapp.msg.CONTAINER_CONFIRM_ORDER = "待确认抢单"
esapp.msg.CONTAINER_DISPATCHED = "已派车"

esapp.msg.CONTAINER_NO_DEPOT = "待指定堆场"
esapp.msg.CONTAINER_NO_MAILING = "待指定寄单点"

// 应收费用message
esapp.msg.please_selecte_order_confrim_bill = "请先选择对账订单"
esapp.msg.please_selecte_order_cost_input = "请先选择费用录入订单"
esapp.msg.please_selecte_update_release_order = "请先选择更新放箱日期订单"
esapp.msg.defaultTitle = "鸭嘴兽"

//2018.8.18新增（陈皓）
esapp.msg.mailingInfoMsg = "寄单点"
esapp.msg.isTakeList = "是否拿单"
esapp.msg.truckInfo = "司机信息"
esapp.msg.taskIds = "任务ID"

//2018.9.29新增（陈皓）用于业务接单 港口更变解析
esapp.msg.portDistrictChange = "港区"

//2019.1.14 补充
esapp.msg.reason = "原因"
esapp.msg.orderNos = "订单号"

//2019.4.9
esapp.msg.customerService = "客服"

esapp.msg.terminateReason = "退关理由"

// 2021.5.11 补充
esapp.msg.bookPickUpDepot = "预提提箱点"

// 2021.5.25 补充
esapp.msg.modifyCntrOperCde = "箱营运人"

//2022.10.23 补充
esapp.msg.transPort = "中转港"

// 2022.11.02补充
esapp.msg.port = "港口"
esapp.msg.portDistrict = "港区"
esapp.msg.toPortName = "中转港"
esapp.msg.cargoSource = "数据提供方式"
esapp.msg.currentCargoSource = "本次箱货信息提供方"
esapp.msg.isDeclared = "是否需要鸭嘴兽报关"
esapp.msg.hasCustomerAppliedEir = "客户是否已放单"
esapp.msg.isVGMCutoff = "是否出数据截单"
esapp.msg.consignmentShippingBill = "是否委托舱单代发"
esapp.msg.needContainerWeighing = "工厂是否需要过磅"
esapp.msg.containerWeighingDesc = "过磅方式描述"
esapp.msg.inspectionType = "是否需要监装"
esapp.msg.inspectionRequirement = "监装要求"

// 20022/12/07补充
esapp.msg.transportType = "产品"

//2023/04/06补充
esapp.msg.doContainerType = "做箱时间是否等开港/通知"
esapp.msg.isUserDoContainer = "是否等通知做箱"

esapp.msg.isTrafficRestricted = "工厂是否禁区"
esapp.msg.containerNoBookedType = "是否预提"
esapp.msg.isCustomsRecord = "是否白卡业务"
esapp.msg.isBondedArea = "是否处于保税区"
esapp.msg.isNeedFile = "是否需要备案"
esapp.msg.containerCount = "箱量"
esapp.msg.carrierName = "船公司"

//2023/07/31补充
esapp.msg.supplierName = "供应商名称"

//2023/12/28 补充
esapp.msg.customsRecordType='白卡加锁类型'
esapp.msg.customsRecordAddress='白卡加封地'
